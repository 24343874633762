import React from 'react'

import Slice404 from '~/components/Slice404'

import { useTracking } from '~/providers/TrackingProvider'

import getErrorPageStaticPropsFunction from '~/data/error-page-data'

function Page404(props) {
  const { errorPage } = props

  return errorPage && <Slice404 {...errorPage} />
}

export default Page404

export const getStaticProps = getErrorPageStaticPropsFunction()
